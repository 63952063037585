
import { FactcheckSuggestion } from '@/types';
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import * as FactcheckRequestServices from '@/services/FactCheckRequestServices';

@Component
export default class NewFactcheckSuggestionDialog extends Vue {
    @VModel({ required: true }) show!: boolean;
    @Prop({default: []}) uniqueSources!: string[]

    dateMenu = false
    loading = false;

    date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    dateFormatted = this.formatDate(this.date)

    request: Partial<FactcheckSuggestion> = {
        claim: '',
        args: '',
        claimSource: '',
    };

    selectedSource = null as any

    search = ''
    tab = 0

    twitterLink = ''

    sources = [
        {
            text: 'Twitter',
            value: 'twitter'
        },
        {
            text: 'Website',
            value: 'website'
        },
        {
            text: 'Email',
            value: 'email'
        },
        {
            text: 'Facebook',
            value: 'facebook'
        },
        {
            text: 'Instagram',
            value: 'instagram'
        },
        {
            text: 'TikTok',
            value: 'tiktok'
        },
        {
            text: 'WhatsApp',
            value: 'whatsapp'
        },
        {
            text: 'Andere',
            value: 'other'
        }
    ]

    get computedDateFormatted() {
        return this.formatDate(this.date);
    }

    @Watch('date')
    onDateChange() {
        this.dateFormatted = this.formatDate(this.date);
    }


    parseDate(date: string) {
        if (!date) return ''

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    formatDate(date: string) {
        if (!date) return ''

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
    }

    async addManualSuggestion() {
        this.loading = true;
        await FactcheckRequestServices.CreateRequest({
            ...this.request,
            source: this.selectedSource.value,
            date: this.date,
        });
        this.$emit('added');
        this.loading = false;
        this.show = false;
    }

    async addTwitterSuggestion() {
        this.loading = true;
        if (this.twitterLink === '') {
            this.loading = false;
            return;
        }
        await FactcheckRequestServices.CreateRequestFromTweet(this.twitterLink);
        this.$emit('added');
        this.loading = false;
        this.show = false;
    }

    async addSuggestion() {
        if (this.tab === 0) {
            this.addManualSuggestion()
        } else {
            this.addTwitterSuggestion()
        }
    }

    @Watch('uniqueSources')
    onUniqueSourcesChange() {
        // add the sources that are not in the list
        this.uniqueSources.forEach(source => {
            if (!this.sources.find(s => s.value === source)) {
                this.sources.push({ text: source, value: source })
            }
        })
    }

}
